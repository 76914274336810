<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="nav"
    >
        <template #button-content>

<!--                <feather-icon-->
<!--                    icon="HelpCircleIcon"-->
<!--                    size="22"-->
<!--                />-->
            <span>
                <rt-icon icon="circle-question" variant="light" :size="22"/>
            </span>
        </template>
        <b-dropdown-item
            link-class="d-flex align-items-center"
        >
<!--            <feather-icon-->
<!--                size="16"-->
<!--                icon="StarIcon"-->
<!--                class="mr-50"-->
<!--            />-->
            <rt-icon icon="stars" variant="light" :size="18"/>
            <span class="ml-50">{{i18nT(`Assistant IA`)}}
            <b-badge class="ren-beta-badge" pill variant="light-primary">BETA</b-badge>
            </span>

        </b-dropdown-item>
        <b-dropdown-item
            link-class="d-flex align-items-center"
        >
<!--            <feather-icon-->
<!--                size="16"-->
<!--                icon="HelpCircleIcon"-->
<!--                class="mr-50"-->
<!--            />-->
            <rt-icon icon="circle-question" variant="light" :size="18"/>
            <span class="ml-50">{{i18nT(`Centre d'aide`)}}</span>
        </b-dropdown-item>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click.prevent = "openChat"
        >
<!--            <feather-icon-->
<!--                size="16"-->
<!--                icon="MessageSquareIcon"-->
<!--                class="mr-50"-->
<!--            />-->
            <rt-icon icon="message" variant="light" :size="18"/>
            <span class="ml-50"
            >{{i18nT(`Chat d'assistance`)}}</span>

        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
            link-class="d-flex align-items-center"
        >
<!--            <feather-icon-->
<!--                size="16"-->
<!--                icon="MailIcon"-->
<!--                class="mr-50"-->
<!--            />-->
            <rt-icon icon="envelope" variant="light" :size="18"/>
            <span class="ml-50">{{i18nT(`Nous contacter`)}}</span>
        </b-dropdown-item>
       </b-nav-item-dropdown>
</template>
<script>
import {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from 'bootstrap-vue'


export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    methods: {
        openChat(){
            this.$emit('open')
        },
    }
}
</script>

<style lang="scss">

.ren-beta-badge {
    font-size: 0.6rem;
    padding: 0.1rem 0.3rem;
    font-weight: 500;
    border-radius: 10px;
    background-color: #f3f4f6;
    color: #5e5873;
    transform: translateY(-70%);
}

</style>