/* eslint-disable */
// prettier-ignore
export default {
	// pages: {
	// 	key: 'title',
	// 	data: [
	// 		{ title: `Nouveau bien`, route: { name: 'access-control' }, icon: ['fas', 'home'], isBookmarked: true },
	// 		{ title: `Nouveau locataire`, route: { name: 'create-tenant' }, icon: ['fas', 'user-check'], isBookmarked: true },
	// 		{ title: `Nouvelle location`, route: { name: 'card-advance' }, icon: ['fas', 'key'], isBookmarked: true },
	// 		{ title: `Nouvelle réservation`, route: { name: 'components-alert' }, icon: ['fas', 'calendar-week'], isBookmarked: false },
	// 		{ title: `Nouvel inventaire`, route: { name: 'card-analytic' }, icon: ['fas', 'couch'], isBookmarked: false },
	// 		{ title: `Nouvel état des lieux`, route: { name: 'charts-apex-chart' }, icon: ['fas', 'clipboard-list'], isBookmarked: false },
	// 		{ title: `Nouveau contact`, route: { name: 'components-aspect' }, icon: ['fas', 'address-card'], isBookmarked: false },
	// 		{ title: `Nouvelle intervention`, route: { name: 'extensions-auto-suggest' }, icon: ['fas', 'screwdriver-wrench'], isBookmarked: false },
	// 		{ title: `Nouvelle tâche`, route: { name: 'components-avatar' }, icon: ['fas', 'clipboard-check'], isBookmarked: false },
	// 		{ title: `Nouvel événement`, route: { name: 'components-badge' }, icon: ['fas', 'calendar-days'], isBookmarked: false },
	// 		{ title: `Nouvelle note`, route: { name: 'card-basic' }, icon: ['fas', 'note-sticky'], isBookmarked: false },
	// 		{ title: `Nouveau document`, route: { name: 'pages-blog-detail', params: { id: 1 } }, icon: ['fas', 'file-lines'], isBookmarked: false },
	// 		{ title: `Nouveau message`, route: { name: 'pages-blog-edit', params: { id: 1 } }, icon: ['fas', 'comment-dots'], isBookmarked: false },
	// 		{ title: `Nouveau candidat`, route: { name: 'pages-blog-list' }, icon: ['fas', 'user'], isBookmarked: false },
	// 		{ title: `Ajouter un revenu`, route: { name: 'pages-blog-list' }, icon: ['fas', 'circle-plus'], isBookmarked: true },
	// 		{ title: `Ajouter une dépense`, route: { name: 'pages-blog-list' }, icon: ['fas', 'circle-minus'], isBookmarked: true },
	// 	],
	// },

	pages: {
		key: 'title',
		data: [
			{ title: `Nouveau bien`, route: { name: 'access-control' }, icon: ['light', 'home'], isBookmarked: true },
			{ title: `Nouveau locataire`, route: { name: 'create-tenant' }, icon: ['light', 'user-check'], isBookmarked: true },
			{ title: `Nouvelle location`, route: { name: 'card-advance' }, icon: ['light', 'key'], isBookmarked: true },
			{ title: `Nouvelle réservation`, route: { name: 'components-alert' }, icon: ['light', 'calendar-week'], isBookmarked: false },
			{ title: `Nouvel inventaire`, route: { name: 'card-analytic' }, icon: ['light', 'couch'], isBookmarked: false },
			{ title: `Nouvel état des lieux`, route: { name: 'charts-apex-chart' }, icon: ['light', 'clipboard-list'], isBookmarked: false },
			{ title: `Nouveau contact`, route: { name: 'components-aspect' }, icon: ['light', 'address-card'], isBookmarked: false },
			{ title: `Nouvelle intervention`, route: { name: 'extensions-auto-suggest' }, icon: ['light', 'screwdriver-wrench'], isBookmarked: false },
			{ title: `Nouvelle tâche`, route: { name: 'components-avatar' }, icon: ['light', 'clipboard-check'], isBookmarked: false },
			{ title: `Nouvel événement`, route: { name: 'components-badge' }, icon: ['light', 'calendar-days'], isBookmarked: false },
			{ title: `Nouvelle note`, route: { name: 'card-basic' }, icon: ['light', 'note-sticky'], isBookmarked: false },
			{ title: `Nouveau document`, route: { name: 'pages-blog-detail', params: { id: 1 } }, icon: ['light', 'file-lines'], isBookmarked: false },
			{ title: `Nouveau message`, route: { name: 'pages-blog-edit', params: { id: 1 } }, icon: ['light', 'comment-dots'], isBookmarked: false },
			{ title: `Nouveau candidat`, route: { name: 'pages-blog-list' }, icon: ['light', 'user'], isBookmarked: false },
			{ title: `Ajouter un revenu`, route: { name: 'pages-blog-list' }, icon: ['light', 'circle-plus'], isBookmarked: true },
			{ title: `Ajouter une dépense`, route: { name: 'pages-blog-list' }, icon: ['light', 'circle-minus'], isBookmarked: true },
		],
	},
}
/* eslint-enable */
