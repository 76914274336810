<template>
    <div
        class="main-menu menu-fixed menu-accordion menu-shadow mb-1"
        :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <!-- main menu header-->
        <div class="navbar-header expanded mb-1">
            <slot
                name="header"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :toggleCollapsed="toggleCollapsed"
                :collapseTogglerIcon="collapseTogglerIcon"
            >
                <ul class="nav navbar-nav flex-row">

                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto logo-container">
                        <b-link
                            class="navbar-brand"
                            to="/"
                        >
              <span class="brand-logo">
                  <b-img
                      v-if="miniMenu"
                      class="logo-collapsed"
                      :src="appLogoImage"
                      alt="rentila"
                      width="70"
                      height="70"
                  />
                <b-img
                    v-if="!miniMenu"
                    class="height-35 ml-1"
                    :src="logoVariantUrl"
                    alt="rentila"
                />
              </span>
                        </b-link>
                    </li>

                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle mt-1 mb-1">
                        <b-link class="nav-link modern-nav-toggle">
                            <span @click="toggleVerticalMenuActive">
                                <rt-icon icon="x" variant="light d-block d-xl-none" :size="16" />
                            </span>
                            <span @click="toggleCollapsed"><rt-icon :icon="collapseTogglerIconFeather" variant="light d-none d-xl-block collapse-toggle-icon" :size="22" /></span>
<!--                            <feather-icon-->
<!--                                icon="XIcon"-->
<!--                                size="20"-->
<!--                                class="d-block d-xl-none"-->
<!--                                @click="toggleVerticalMenuActive"-->
<!--                            />-->
<!--                            <feather-icon-->
<!--                                :icon="collapseTogglerIconFeather"-->
<!--                                size="20"-->
<!--                                class="d-none d-xl-block collapse-toggle-icon"-->
<!--                                @click="toggleCollapsed"-->
<!--                            />-->
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        <!-- / main menu header-->

        <!-- Shadow -->
        <div
            :class="{'d-block': shallShadowBottom}"
            class="shadow-bottom"
        />

        <!-- main menu content-->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
        >
            <vertical-nav-menu-items
                :items="navMenuItems"
                class="navigation navigation-main"
            />
        </vue-perfect-scrollbar>
        <!-- /main menu content-->
    </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import store from "@/store";

export default {
    components: {
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BLink,
        BImg,
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true,
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true,
        },
    },
    computed: {
        logoVariantUrl(){
            if (store.state.appConfig.layout.skin === 'dark') {
                return this.appLogoImageDark;
            } else {
                return this.appLogoImageFull;
            }
        },
        miniMenu() {
            return !(!this.isVerticalMenuCollapsed || (this.isVerticalMenuCollapsed && this.isMouseHovered))
        }
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered,
        } = useVerticalNavMenu(props)

        const { skin } = useAppConfig()

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false)

        provide('isMouseHovered', isMouseHovered)

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'circle' : 'circle-dot'))

        // App Name
        const { appName, appLogoImageFull, appLogoImageDark, appLogoImage } = $themeConfig.app

        return {
            navMenuItems,
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImageFull,
            appLogoImageDark,
            appLogoImage,
        }
    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.logo-collapsed {
   position: absolute;
    right: -40px;
}

.logo-container {
    position: relative;
}

.transition-item {
    transition: all 0.3s ease;
}

.softswitch {
    padding-left: 30px;
    margin-top: 32px;
    margin-bottom: 20px;
}

.collapsedSwitch {
    padding-left: 18px;
}

.collapsedSofts label:not(.active) {
    display: none;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

</style>
