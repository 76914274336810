<template>
    <p className="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
          className="ml-25"
          href="https://rentila.com"
          target="_blank"
      >Rentila</b-link>
      <span class="d-none d-sm-inline-block"></span>
    </span>

        <span v-if="false"
              class="float-md-right d-none d-md-block">{{ i18nT(`Hand-crafted`) }} &amp; {{ i18nT(`Made with`) }}
      <feather-icon
          icon="HeartIcon"
          size="21"
          className="text-danger stroke-current"
      />
    </span>
    </p>
</template>

<script>
import {BLink} from 'bootstrap-vue'

export default {
    components: {
        BLink,
    },
}
</script>
