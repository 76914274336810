export default [
  {
    header: "L'ESSENTIEL",
  },
  {
    title: 'Bureau',
    route: 'dashboard-manager',
    icon: 'gauge-max',
  },
  {
    title: 'Clients',
    route: 'clients',
    icon: 'user-tie',
  },
  {
    title: 'Mandats',
    route: 'mandates',
    icon:  'file-contract',
  },
  {
    title: 'Biens',
    icon:  'home',
    children: [{
        title: 'Lots',
        route: {name: 'properties'},
        icon: 'door-open'
      },
      {
        title: 'Immeubles',
        route: {name: 'buildings'},
        icon:  'building'
      }
    ]
  },
  {
    title: 'Locataires',
    route: 'tenants',
    icon: 'user-group',
  },
  {
    title: 'Locations',
    route: 'leases',
    icon: 'key',
  },
  {
    title: 'Réservations',
    route: 'reservations',
    icon:  'calendar-week',
  },
  {
    title: 'Inventaires',
    route: 'inventory',
    icon:  'loveseat',
  },
  {
    title: 'Etat des lieux',
    route: 'handovers',
    icon:  'clipboard-list',
  },
  {
    title: 'Finances',
    route: 'finances-home',
    icon:  'coins',
  },
  // {header:'LE PLUS'},
  {
    title: 'Corbeille',
    route: 'trash',
    icon:  'trash',
  },
  //
  // {
  //   title: 'Assistant IA',
  //   route: 'assistant-ia',
  //   icon: ['fas', 'robot'],
  // },
  // {
  //   title: 'Quiz',
  //   route: 'quiz',
  //   icon: ['fas', 'question-circle'],
  // },
  // {
  //   title: 'Enquête',
  //   route: 'enquete',
  //   icon: ['fas', 'search'],
  // },
  // {
  //   title: 'Parrainage',
  //   route: 'parrainage',
  //   icon: ['fas', 'handshake'],
  // },
  // {
  //   title: 'Annonces',
  //   route: 'annonces',
  //   icon: ['fas', 'bullhorn'],
  // },
  // {
  //   title: 'Abonnements',
  //   route: 'abonnements',
  //   icon: ['fas', 'receipt'],
  // },
  // {
  //   title: 'Commandes',
  //   route: 'commandes',
  //   icon: ['fas', 'shopping-cart'],
  // },
  // {
  //   title: 'Factures',
  //   route: 'factures',
  //   icon: ['fas', 'file-invoice-dollar'],
  // },
  // {
  //   title: 'Transactions',
  //   route: 'transactions',
  //   icon: ['fas', 'exchange-alt'],
  // },
  // {
  //   title: 'Indices',
  //   route: 'indices',
  //   icon: ['fas', 'chart-line'],
  // },
  // {
  //   title: 'Export',
  //   route: 'export',
  //   icon: ['fas', 'file-export'],
  // },
  // {
  //   title: 'Localisation',
  //   route: 'localisation',
  //   icon: ['fas', 'map-marker-alt'],
  // },
  // {
  //   title: 'Réglages',
  //   route: 'reglages',
  //   icon: ['fas', 'cog'],
  // },
  // {
  //   title: 'Cronjobs',
  //   route: 'cronjobs',
  //   icon: ['fas', 'clock'],
  // },
  // {
  //   title: 'Sécurité',
  //   route: 'securite',
  //   icon: ['fas', 'shield-alt'],
  // },
  // {
  //   title: 'Bureau',
  //   route: 'bureau',
  //   icon: ['fas', 'dice-d6'],
  // }
];
