<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card suggestion-box">
            <div class="card-body">
              <h4 class="mb-2">
                  <rt-icon icon="feather" variant='light' :size="22" />
                {{i18nT(`Comment pourrions-nous améliorer notre site pour vous?`)}}
              </h4>
              <div
                role="group"
                class="form-group"
              >
                <label
                  for="suggestionbox"
                  class="d-block"
                >{{i18nT(`Veuillez saisir vos idées et vos suggestions...`)}}</label>
                <div>
                  <textarea
                    id="suggestionbox"
                    name="suggestionbox"
                    rows="3"
                    wrap="soft"
                    class="form-control"
                    v-model="suggestionText"
                  >
                    </textarea>
                </div>
              </div>
              <div class="row">
                <div style="flex: 1 1 0%;">
                </div>
                <button
                  type="button"
                  class="btn mt-0 md-2 ml-1 mr-1 btn-outline-secondary"
                  :disabled="!suggestionText"
                  @click="sendSuggestion"
                >
                  {{i18nT(`Envoyer vos suggestions`)}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  data() {
    return {
      suggestionText: ''
    }
  },
  methods: {
    sendSuggestion() {
      // TODO set up method for suggestions
    }
  }
}
</script>

<style>

</style>
