<template>
  <b-navbar-nav class="nav" @hidden="resetsearchQuery">
    <b-nav-item-dropdown
      link-classes="bookmark-star"
      lazy
      @hidden="resetsearchQuery"
    >
        <!-- Button Content -->
<!--      <feather-icon-->
<!--        slot="button-content"-->
<!--        icon="PlusCircleIcon"-->
<!--        size="21"-->
<!--      />-->
        <span slot="button-content" class="d-flex">
        <rt-icon :icon="'circle-plus'" :variant="'light'" :size="21"/>
        </span>


      <!-- Dropdown Content -->
      <li style="min-width:200px">
        <div class="p-1">
          <b-form-input
            v-model.lazy="searchQuery"
            placeholder="Chercher"
          />
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="search-list search-list-bookmark scroll-area"
          :class="{'show': filteredData.pages && filteredData.pages.length }"
          tagname="ul"
        >

          <b-dropdown-item
            v-for="(suggestion, index) in filteredData.pages || bookmarks"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center"
            :to="suggestion.route"
            @mouseover="currentSelected = index"
            @click="resetsearchQuery"
          >
              <span
                    :class="suggestion.icon.includes('circle-plus') ? 'text-primary' : suggestion.icon.includes('circle-minus') ? 'text-danger' : ''"
                    class="ren-icon-container"
              >
                  <rt-icon :icon="suggestion.icon[1]" :variant="suggestion.icon[0]"  :size="16"/>
              </span>
                  <span class="ml-50">{{ i18nT(suggestion.title) }}</span>

          </b-dropdown-item>
          <b-dropdown-item
            v-show="!(filteredData.pages && filteredData.pages.length) && searchQuery"
            disabled
          >
            No Results Found.
          </b-dropdown-item>
        </vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import quickAddOptions from '../quick-add-options'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, VuePerfectScrollbar, BDropdownItem,
  },
  setup() {
    const quickAddOptionsData = ref(quickAddOptions.pages)
    const bookmarks = ref(quickAddOptions.pages.data.filter(page => page.isBookmarked))
    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 500,
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({ data: { pages: quickAddOptionsData.value } })


    watch(filteredData, val => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })

    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push(suggestion.route).catch(() => {})

      resetsearchQuery()
    }


    return {
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      // toggleBookmarked,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
    data() {
        return {
            showIcon: false,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmark-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}

.ren-icon-container {
    width: 24px;
    display: flex;
    justify-content: center;
}
</style>
