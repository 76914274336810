<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex ">

<!--            <div class="d-flex">-->
                <quick-add class="mr-50"/>
                <span class="justify-content-center"><rt-icon :icon="'calendar'" :variant="'light'" :size="21"/></span>
<!--            </div>-->
<!--            <feather-icon icon="CalendarIcon" class="cursor-pointer" size="21" />-->
        </div>

<!--        Right Col-->

        <b-navbar-nav class="nav align-items-center ml-auto">
            <search-bar />
            <dark-Toggler class="d-none d-lg-block" />
            <notification-dropdown />
            <help-dropdown  @open="openChat" />
            <b-nav-item-dropdown
                right
                toggle-class="d-flex dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{i18nT(`Bonjour`)+', '+ userData.LandlordFirstName || userData.LandlordEmail}}
                        </p>
                        <span class="user-status">Admin</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        :src="require('@/assets/images/avatars/13-small.png')"
                    />
                </template>

                <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'landlord-profile'}">
                    <rt-icon :icon="'address-card'" :variant="'light'" :size="18"/>
                    <span class="ml-50">{{i18nT(`Mon compte`)}}</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <rt-icon :icon="'envelope'" :variant="'light'" :size="18"/>
                    <span class="ml-50">Inbox</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <rt-icon :icon="'message'" :variant="'light'" :size="18"/>
                    <span class="ml-50">
                        {{i18nT(`Messages système`)}}
                        <b-badge class="ren-beta-badge" pill variant="light-primary">BETA</b-badge>
                    </span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'landlord-settings'}">
                    <rt-icon :icon="'gear'" :variant="'light'" :size="18"/>
                    <span class="ml-50">{{i18nT(`Réglages`)}}</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item
                    @click="logout"
                    link-class="d-flex align-items-center">
<!--                    <feather-icon-->
<!--                        size="16"-->
<!--                        icon="LogOutIcon"-->
<!--                        class="mr-50"-->
<!--                    />-->
                    <rt-icon :icon="'arrow-right-from-bracket'" :variant="'light'" :size="18"/>
                    <span class="ml-50">{{i18nT(`Déconnexion`)}}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useAuth from '@/auth/useAuth'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown.vue";
import CartDropdown from "@core/layouts/components/app-navbar/components/CartDropdown.vue";
import SearchBar from "@core/layouts/components/app-navbar/components/SearchBar.vue";
import Bookmarks from "@core/layouts/components/app-navbar/components/Bookmarks.vue";
import QuickAdd from "@core/layouts/components/app-navbar/components/QuickAdd.vue";
import HelpDropdown from "@core/layouts/components/app-navbar/components/HelpDropdown.vue";
import {localize} from "vee-validate";

export default {
    components: {
        HelpDropdown,
        QuickAdd,
        Bookmarks,
        SearchBar,
        CartDropdown,
        NotificationDropdown,
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
           localCrisp: this.$crisp,
        }
    },
    computed: {
        userData() {
            return JSON.parse(localStorage.getItem('userData'));
        },
    },
    created() {

        const lang = useAuth.getLocale()
        this.changeLang(lang)
        localize(lang);
    },
    methods: {
        logout() {

            //Stop message pinger
            //clearInterval(this.$store.state.app.msgRefreshIntervalId);
            //this.$http.post('logout').then(()=>{
            // Remove userData from localStorage
            localStorage.removeItem(useAuth.authConfig.storageTokenKeyName);
            localStorage.removeItem(useAuth.authConfig.storageUserTypeKeyName);
            localStorage.removeItem(useAuth.authConfig.storageRefreshTokenKeyName);
            localStorage.removeItem(useAuth.authConfig.storageCurWorkspaceKeyName);
            localStorage.removeItem(useAuth.authConfig.storageWorkspacesKeyName);
            localStorage.removeItem(useAuth.authConfig.storageProfileKeyName);
            localStorage.removeItem(useAuth.authConfig.storageSoftwaresKeyName);
            localStorage.removeItem(useAuth.authConfig.storageCurrentSoftwareKeyName);
            // Remove userData from localStorage
            localStorage.removeItem('userData');

            //Clear also all state data
            useAuth.resetUser()
            // this.$crisp.push(["do", "session:reset"]);

            // Redirect to login page
            this.$router.push({name: 'auth-login'});
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.i18nT(`Déconnexion réussie.`),
                    icon: 'LogOutIcon',
                    variant: 'success',
                },
            })

            // }).catch(
            //     error => {
            //         this.$toast({
            //             component: ToastificationContent,
            //             position: 'top-right',
            //             props: {
            //                 title: this.errorMessage(error),
            //                 icon: 'AlertTriangleIcon',
            //                 variant: 'danger',
            //             },
            //         })
            //     }
            // );



        },
        openChat(){
            this.$crisp.do('chat:open')
        },
    },
    watch: {
        localCrisp: function(val) {
            if(val!=='undefined'){
                let curUser = useAuth.getProfile();
                if(curUser){
                    this.$crisp.push(["set", "user:nickname", [curUser.LandlordFullName+" (U:"+curUser.LandlordID+")"]]);
                    this.$crisp.push(["set", "user:email", [curUser.LandlordEmail]]);
                }
            }
        }
    }
}
</script>

<style lang="scss">

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu{
    width: auto;
}

</style>
